import { createApp } from "vue"
import App from './App.vue'
import router from './router'
import store from "./store"
import interceptorsSetup from './configs/axios'

interceptorsSetup()

const app = createApp(App).use(store).use(router)

/* app.component('ValidationProvider', ValidationProvider) */
app.mount("#app")
