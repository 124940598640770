import { apiPostNonAuth } from '../../../utils/api'
import { API_MOBILE } from '../../../utils/api-url'

const state = () => {
  return {
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '',
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loading: false
    }
  }
}

const mutations = {
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state: any, payload: Object) {
    state.toggle_modal_add_edit_discard = payload
  }
}

interface Erro {
  err: string
}
const actions = {
  async resetPassword(context: any, payload: Object) {
    try {
      const response = await apiPostNonAuth(API_MOBILE.RESET_PASSWORD, payload)
      return response.data
    } catch (err: any) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,
        title: '',
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loading: false
      })
      return err
    }
  },
  async emailVerif(context: any, payload: Object) {
    try {
      const response = await apiPostNonAuth(API_MOBILE.EMAIL_VERIFICATION, payload)
      return response.data
    } catch (err: any) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,
        title: '',
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        message: err.response.data.message,
        loading: false
      })
      return err
      // notificationDanger(err)
    }
  }
}

const getters = {
  get_toggle_modal_add_edit_discard (state:any) {
    return state.toggle_modal_add_edit_discard
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
