<template>
  <div
    v-show="getToggleModalAddEditDiscard.loading"
    class="uk-margin-auto-vertical"
  >
    <div uk-spinner="ratio:2" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { notificationDanger } from '../../../utils/notification'

export default {
  computed: {
    ...mapGetters({
      getToggleModalAddEditDiscard: 'mobile/get_toggle_modal_add_edit_discard'
    })
  },
  mounted() {
    this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loading: true})
    if (this.$route.query && this.$route.query.token) {
      this.$router.push({ name: 'EmailVerification', query:{ token: this.$route.query.token }})
      const data = {
        token: this.$route.query.token
      }
      this.emailVerif(data).then(result => {
        if (result.message == 'OK') {
          setTimeout(() => {
            this.setToggleModalAddEditDiscard({
              ...this.getToggleModalAddEditDiscard,
              type: 'consumer',
              loading: false
            })
            this.$router.push({ name: 'SuccessPage' })
          }, 3000)
        } else {
          notificationDanger(result)
          setTimeout(() => {
            this.$router.push({ name: 'FailedPage' })
            this.setToggleModalAddEditDiscard({
              ...this.getToggleModalAddEditDiscard, 
              type: 'failed',
              loading: false
            })
            
          }, 2000)
        }
      })
    } else {
      setTimeout(() => {
        this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loading: true})
        this.setToggleModalAddEditDiscard({
          ...this.getToggleModalAddEditDiscard,
          type: '',
          loading: true
        })
        this.$router.push({path: '/:catchAll(.*)'})
      }, 5000)
    }
  },
  methods: {
    ...mapActions({
      emailVerif: 'mobile/emailVerif'
    }),
    ...mapMutations({
      setToggleModalAddEditDiscard: 'mobile/SET_TOGGLE_MODAL_ADD_EDIT_DISCARD'
    })
  }
}
</script>
