<template>
  <div>
    <h1 class="unauthorized-text">
      Page Not Found
    </h1>
  </div>
</template>

<style scoped>
.unauthorized-text {
  padding-top: 200px;
  margin: auto;
}
</style>
