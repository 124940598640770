<template>
  <div class="reset-password-mobile">
    <div class="header-reset uk-text-left">
      <h4>Reset Password</h4>
      <img
        :src="`${images}/logo-superindo.png`"
        alt="logo-lsi"
      >
      <p>
        Silahkan atur ulang kembali password anda dengan mengisi field dibawah ini.
        Password minimum adalah 8 karakter dan alphanumeric (A-Z,0-9)
      </p>
    </div>
    
    <Form
      v-slot="{ meta, values, errors }"
      :validation-schema="schema"
      @submit="onSubmit"
    >
      <div class="uk-margin uk-text-left">
        <div class="uk-inline uk-width-1-1">
          <img
            v-if="arrayVisible.includes(1)"
            class="uk-form-icon-custom uk-form-icon-flip"
            :src="`${images}/icon/ic_eye_on.svg`"
            alt="eye-on-icon"
            @click="handleVisible(1)"
          >
          <img
            v-else
            class="uk-form-icon-custom uk-form-icon-flip"
            :src="`${images}/icon/ic_eye_off.svg`"
            alt="eye-off-icon"
            @click="handleVisible(1)"
          >
          <label>Create a Password</label>
          <Field
            name="new"
            class="uk-input"
            :type="arrayVisible.includes(1) ? 'password' : 'text'"
            placeholder="Create password "
            :validate-on-input="true"
            @input="handleValidation($event.target.value)"
            @blur="handleValidation($event.target.value)"
          />
          <ErrorMessage
            v-if="errors?.new"
            name="new" 
            as="p"
            class="error"
          />
          <p
            v-if="meta.touched && !validation.lowerUpper"
            class="error"
          >
            New Password at least one upper and lower alpha character
          </p>
          <p
            v-if="meta.touched && !validation.numeric"
            class="error"
          >
            New Password at least one number
          </p>
        </div>
      </div>
      <div class="uk-margin uk-text-left">
        <div class="uk-inline uk-width-1-1">
          <label>Confirm The Password</label>
          <img
            v-if="arrayVisible.includes(2)"
            class="uk-form-icon-custom uk-form-icon-flip"
            :src="`${images}/icon/ic_eye_on.svg`"
            alt="eye-on-icon"
            @click="handleVisible(2)"
          >
          <img
            v-else
            class="uk-form-icon-custom uk-form-icon-flip"
            :src="`${images}/icon/ic_eye_off.svg`"
            alt="eye-off-icon"
            @click="handleVisible(2)"
          >
          <Field
            name="confirm"
            class="uk-input"
            :type="arrayVisible.includes(2) ? 'password' : 'text'"
            placeholder="Confirm password"
            :validate-on-input="true"
          />
          <ErrorMessage 
            name="confirm" 
            as="p"
            class="error"
          />
        </div>
      </div>
      <button
        v-if="!getToggleModalAddEditDiscard.loading"
        class="uk-button uk-width-1-1"
        :class="meta.valid && handleValidation(values && values.new) ? 'red' : 'disabled'"
        :disabled="!meta.valid || !handleValidation(values && values.new)"
      >
        Save
      </button>
      <button
        v-else
        class="uk-button uk-width-1-1 red"
        type="button"
        disabled
      >
        <div uk-spinner />
      </button>
    </Form>
  </div>
  <div v-show="getToggleModalAddEditDiscard.type == 'failed'">
    <failed-page />
  </div>
  <div v-if="getToggleModalAddEditDiscard.type == 'user'">
    <success-page :get-toggle-modal-add-edit-discard="getToggleModalAddEditDiscard" />
  </div>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant'
import { notificationDanger } from '@/utils/notification'
import { Field, Form, ErrorMessage } from 'vee-validate'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import FailedPage from './FailedPage'
import SuccessPage from './SuccessPage'
import * as yup from 'yup'

export default {
  components: {
    Field, 
    Form, 
    ErrorMessage,
    SuccessPage,
    FailedPage
  },
  data() {
    const schema = yup.object({
      new: yup.string().min(8).required('New Password is required').label("New Password"),
      confirm: yup.string().required('Password Confirmation is required')
        .oneOf([yup.ref('new'), null], 'Passwords must be match')
    })
    return {
      images: PREFIX_IMAGE,
      arrayVisible: [1, 2],
      validation: {
        lowerUpper: null,
        numeric: null
      },
      schema
    }
  },
  computed: {
    ...mapGetters({
      getToggleModalAddEditDiscard: 'mobile/get_toggle_modal_add_edit_discard'
    })
  },
  mounted() {
    if (this.$route.query && this.$route.query.token) {
      this.setToggleModalAddEditDiscard({
        ...this.getToggleModalAddEditDiscard, 
        type: '', 
        loading: false
      })
      this.$router.push({ name: 'MobileResetPassword', query:{ token: this.$route.query.token }})
    } else {
      this.$router.push({path: '/:catchAll(.*)'})
    }
  },
  methods: {
    ...mapActions({
      actResetPassword: 'mobile/resetPassword'
    }),
    ...mapMutations({
      setToggleModalAddEditDiscard: 'mobile/SET_TOGGLE_MODAL_ADD_EDIT_DISCARD'
    }),
    handleValidation(value) {
      const templateRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]{8,}$/
      const upper = /[A-Z]/
      const lower = /[a-z]/
      const number = /[0-9]/

      if (upper.test(value) && lower.test(value)) {
        this.validation.lowerUpper = true
      } else {
        this.validation.lowerUpper = false
      }
      if (number.test(value)) {
        this.validation.numeric = true
      } else {
        this.validation.numeric = false
      }
      const result = templateRegex.test(value)
      return result
    },
    handleVisible(param) {
      if (this.arrayVisible.includes(param)) {
        const newArr = this.arrayVisible.filter(item => item != param)
        this.arrayVisible = newArr
      } else {
        this.arrayVisible.push(param)
      }
    },
    onSubmit(values) {
      const newData = {
        token: this.$route.query.token || '',
        new: values.new,
        confirm: values.confirm
      }
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loading: true})
      this.actResetPassword(newData).then(result => {
        if (result.message == 'OK') {
          setTimeout(() => {
            this.setToggleModalAddEditDiscard({
              ...this.getToggleModalAddEditDiscard,
              type: 'user',
              loading: false
            })
            this.$router.push({ name: 'SuccessPage' })
          }, 2000)
        } else {
          notificationDanger(result)
          setTimeout(() => {
            this.setToggleModalAddEditDiscard({
              ...this.getToggleModalAddEditDiscard, 
              type: 'failed', 
              loading: false
            })
            this.$router.push({ name: 'FailedPage' })
          }, 2000)
        }
      })
    }
  }
}
</script>

<style scoped>
  .reset-password-mobile {
    height: 80vh;
    margin-bottom: 800px !important;
    border-radius: 8px;
    width: 600px;
    margin: 20px auto;
    padding: 20px;
    box-shadow: 0px 0px 2px 5px rgba(222,219,219,0.75);
  -webkit-box-shadow: 0px 0px 2px 5px rgba(222,219,219,0.75);
  -moz-box-shadow: 0px 0px 2px 5px rgba(222,219,219,0.75);
  }
  .header-reset img {
    width: 120px;
  }
  .uk-margin.uk-text-left {
    margin-top: 24px !important;
  }
  h4 {
    margin-top: 8px;
    font-weight: 700;
    font-size: 24px !important;
    color: #000 !important;
  }
  label {
    display: inline-block;
    margin-bottom: 8px;
    font-family: 'jakartaRegular';
    font-size: 16px !important;
    color: #22252F !important;
  }
  .uk-button.uk-button-primary {
    background-color: #E93535;
    height: 48px;
    border-radius: 8px;
  }
  .uk-form-icon-custom {
    position: absolute;
    top: 45px;
    width: 24px;
    height: 24px;
    right: 15px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #999;
    cursor: pointer;
  }
  .password {
    padding-left: 0;
  }
  .password li {
    list-style: none;
  }
  input::placeholder {
    height: 20px;
    border-radius: 8px;
  }
  @media screen and (max-width: 600px) {
    .reset-password-mobile {
      padding: 40px;
      box-shadow: none;
    }
  }

</style>
