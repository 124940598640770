<!-- eslint-disable  -->
<template>
  <div v-html="`<ol>
<li><strong>What kind of Information do we collect?</strong></li>
</ol>
<p>We collect information from you when you register on our application or fill out a registration form at our store. All the data that you put in the application will be or the registration form email address, phone number, your physical address, gender, your birthday and etc will be collected by us.</p>
<p>You may be asked to activate your Geo-Location to enable us giving you a better experience. We also track the behavior of your personal use in using our application such as how often you open the application, when the most time you open the application, which function that you preferred to click and etc. The purpose is to assist and provide convenience and better experience for you.<br />&nbsp;</p>
<ol>
<li><strong>What will we do with that information?</strong></li>
</ol>
<p>Any information that we collect from you may be used for following ways:</p>
<ul>
<li>To personalize your experience (your information helps us to better respond to your individual needs).</li>
<li>To improve our application (we continually strive to improve our application offering and promoting based on the information and feedback that we receive from you)</li>
<li>To send the information and updates related product or service to the email address that you had registered.<br />&nbsp;</li>
</ul>
<ol>
<li><strong>How long do will we keep the information?</strong></li>
</ol>
<p>We retain your information for a limited amount of time and we will delete or anonymize the information after is no longer necessary for the purpose of processing.<br />&nbsp;</p>
<ol>
<li><strong>How do we protect your information?</strong></li>
</ol>
<p>We implement a variety of security measures to maintain the safety of your personal information when you submit, add, change, access or send your personal information.</p>
<p>We also trained our personnel to always practice safety while treating your personal information and if there were any suspected data breaches will be reported in accordance with applicable law.<br />&nbsp;</p>
<ol>
<li><strong>Do we use cookies?</strong></li>
</ol>
<p>Since the platform is mobile application, we do not use cookies. However to help us developing the application and provide better personalized experience, we develop our own function and feature to compile aggregate data and interactions between your behaviors with our application if required. We may contract with third party service providers to assist us in better understanding our customers. These providers are not permitted to use the information collected on our behalf except to help us conduct and improve our business.</p>
<p>&nbsp;</p>
<ol>
<li><strong>Do we disclose any information to outside parties?</strong></li>
</ol>
<p>We do not sell, trade or otherwise transfer to outside third parties your personally identifiable information. This does not include trusted third parties who assist us in conducting our business, or servicing you so long as those parties agree this information confidential. We may disclose your information when we believe it is appropriate to comply with the law, enforce our policies, or protect ours or other rights, property, or safety. However non personally identifiable information may be provided to other parties for marketing, advertising and other uses.<br />&nbsp;</p>
<ol>
<li><strong>Change to this Privacy Policy</strong></li>
</ol>
<p>We may update our Privacy Policy from time to time. Thus you are advised to review periodically for any changes. We will notify you of any changes by publishing the new Privacy Policy on this page. The changes are applicable immediately after they are posted on the page.</p>
<p>&nbsp;</p>
<ol>
<li><strong>Governing Law, Jurisdiction, and Miscellaneous</strong></li>
</ol>
<p>This Privacy Policy shall be regulated and interpreted pursuant to this law of Republic of Indonesia. Any and all dispute are arising from this Privacy Policy shall be regulated by the exclusive jurisdiction of the District Court of South Jakarta.<br />&nbsp;</p>
<ol>
<li><strong>Acknowledgement and Consent</strong></li>
</ol>
<p>Before using this application, you will be asked to acknowledge that you have read and understood this Privacy Policy and the Term of Use and agree and accede to the use, practice, processing, and transfer of your personal information to us as stated in this Privacy Policy by clicking the confirmation button.</p>
<p>You also represent that you are entitled to share all of the information you have provided to us and to grant us the right to use and share such information as a stated in this Privacy Policy.<br />&nbsp;</p>
<ol>
<li><strong>Your rights</strong></li>
</ol>
<p>You can request to access, rectify, erase or restrict personal data that you have previously provided to us. You can also object to our use of your personal data based on consent by requesting to us. We explain each of these requests below. If you would like to exercise any of your rights, you can send an email to us and we will respond to your request consistent with applicable law. We explain each of these requests below.</p>
<p>&nbsp;</p>
<p><em>Access</em></p>
<p>You are entitled to request access to the personal data we hold about you and to learn details about what data we collect regarding your own personal data.<br />&nbsp;</p>
<p><em>Updates</em></p>
<p>We take reasonable steps to ensure that the information we hold about you is accurate and complete. However you have rights to make a request for updating or amending your personal data.</p>
<p>&nbsp;</p>
<p><em>Erasure</em></p>
<p>In certain circumstances, you have the right to ask us to erase your personal data for example where the personal data we collected is no longer necessary for the original purpose or where you withdraw your consent. However, this will need to be balanced against other factors. For example we may not be able comply with your request due to certain legal or regulatory obligations.<br />&nbsp;</p>
<p><em>Restriction of processing</em></p>
<p>In certain circumstances, you are entitled to ask us to temporarily stop using your personal data. For example where you think that the personal data we hold about you is inaccurate or where you think that we no longer need to use your personal data.</p>
<p>&nbsp;</p>
<p><em>Objection</em></p>
<p>You have the right to object to processing, on grounds particular to your situation and where the processing is based on our legitimate interests. Unless we have a compelling legitimate ground for the processing, we will no longer process the personal data on that basis when you file an objection.</p>
<p>Please note, however, that we may not be able to provide certain services or benefits if we are unable to process the necessary personal data for that purpose.</p>
<p>&nbsp;</p>
<p><em>Withdraw Consent</em></p>
<p>Before you use our application we will ask for your consent to obtain, process and use your personal data. You have right to withdraw your consent at any time.<br />&nbsp;</p>
<ol>
<li><strong>&nbsp;Contacting Us</strong></li>
</ol>
<p>If there are any questions regarding this Privacy Policy, you may contact us at customerservice@superindo.co.id.</p>`"></div>
</template>

<style scoped>
  div {
    text-align: left;
    padding: 50px;
    height: 90vh;
    overflow: scroll;
  }
</style>
