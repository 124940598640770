<template>
  <div
    class="uk-background-cover uk-flex-center uk-flex uk-height-1-1 layout-background"
    uk-grid
  >
    <router-view />
  </div>
</template>

<style scoped>
.layout-background {
  background-color: #FFF;
  overflow: scroll;
  margin: 0;
}
</style>
