<!-- eslint-disable  -->

<template>
  <div v-html="`<ol><li>
<p><strong>Terms &amp; Conditions</strong></p>
<p>Please read these terms and conditions carefully before accessing or using&nbsp;<strong>My Super Indo</strong> Mobile Application (<strong>Application</strong>). By accessing or using this Application, You have read carefully, understood and agreed to the terms and conditions, including My Super Indo Privacy Policy (promotion terms and conditions) and the applicable legal limitation with respect to this Application (hereinafter referred to as <strong>Terms and Conditions</strong>&rdquo;). If You do not agree to comply with these terms and conditions, please do not access the Application.</p>
<p>Super Indo shall be entitled at any time to amend these Terms and Conditions, and such amendment shall come into effect upon its posting through the Application and shall be provided through a notice through the Application. You, as a user of this Application, are responsible for carefully reading such terms every time You use or access the Application. By accessing or using this Application further constitutes an acceptance of amendment to the Terms and Conditions of the Application. These Terms and Conditions shall constitute an integral part of the Privacy Policy on the Application.</p>
<p>We also shall be entitled to change, amend, suspend and/or deactivate all or any part of This Application at any time or upon issuing a notice as required by the applicable laws and regulations. We may launch&nbsp; &nbsp; certain services or features in the beta version, which may not function properly or is similar to the final version, and we shall not be liable for such matter. We may also restrict certain features or restrict Your access to any part or all of the services at its sole discretion and without any notice or liability. We shall be entitled to refuse to grant You the access to the Application or to allow You to open an Account for any reason.</p>
<p><br />&nbsp;</p>
<p>Terms and Conditions of the access and use of the Application are as follows:&nbsp;</p>
<p>1. <strong>GENERAL</strong></p>
<p>This My Super Indo Mobile application is a mobile application owned and managed by PT Lion Super Indo, domiciled in Jakarta Selatan &ndash; Indonesia (hereinafter referred to as <strong>Super Indo</strong> or <strong>We</strong>).</p>
<p>Terms and Conditions of The Application shall constitute an integral part of the Privacy Policy and any other regulations contained on the Application.</p>
<p><br />&nbsp;</p>
<p>This Application is intended for Users domiciled in the jurisdiction of the Republic of Indonesia, both men and women at least 18 (eighteen) years old or married. Further provisions regarding the requirements of membership shall refer to these Terms and Conditions.</p>
<p>This Application and its contents are in accordance with the laws and regulation of the Republic of Indonesia, and intended only for the use by residents of Indonesia. Therefore, the information &nbsp; contained in this Application may be irrelevant or unsuitable for other territories.</p>
<p>2. <strong>About MY SUPER INDO</strong></p>
<p>My Super Indo is a Super Indo service that comes to provide promotional offers, information, educational and communication facilities for the Application users. This Application provides information related to the promotion of products sold in Super Indo, any other promotion information, event information, lifestyle information, recipes and store locations. By accessing&nbsp; &nbsp; This Application, Users fully understand that My Super Indo provides these information and educational information at the request of personal Users.</p>
<p>3. <strong>Accounts and Security of ACCOUNTS</strong></p>
<p>Service on the Application can only be accessed by registered accounts. Therefore, You shall be requested to access the Application by using registered accounts or to register a new account by selecting a user identification (<strong>User ID</strong>) and a unique password, and providing certain personal information. If You choose a user ID deemed to be impolite or inappropriate by Super Indo in its sole discretion, Super Indo shall be entitled to suspend or terminate Your account.</p>
<p>To create a user ID, we shall request information regarding Your personal data which is true, consists of, name, Gender Type, Date of Birth, Email and Phone Number and determine the desired password, with further details as specified in Point 4 (b) of these Terms and Conditions.</p>
<p>You agree to : (a)&nbsp; maintain the confidentiality&nbsp; of Your password and only use Your User ID and&nbsp; password upon login; (b)&nbsp; ensure that You log out of Your account at the end of each session on the Application; (c) promptly notify Super Indo of unauthorized use of Your Account, User ID and/or password; and (d) ensure&nbsp; that&nbsp; Your&nbsp; Account&nbsp; Information is accurate and up to date. You are solely responsible for all activities that are performed by using Your User ID and Account even if such activities or use are not performed by You. Super Indo shall not be liable for any loss or damage arising out from unauthorized use of Your password or Your failure to comply herewith.</p>
<p>You agree that for any reason at Super Indo&rsquo;s sole discretion and with or without notice or policy to You or any third party, Super Indo may terminate Your Account and User ID and remove or discard any Content relating to Your Account and User ID from the Application. The basis for such termination may include, but is not limited to :&nbsp; (a) inactivity for long periods of time; (b) Breach of the provisions of these Terms and Conditions; (c ) conduct that is illegal, deceptive, harassing,&nbsp; defamatory, threatening and/or abusive; (d) having multiple user accounts for unauthorized reasons; and/or (e) behaviors that harm another User, a third party , or the interests of the Super Indo business. The use of Account for illegal, deceptive, harassing, defamatory, threatening and/or abusive purposes may be brought to law enforcement authorities without notice to You. If any legal issue arises out or law enforcement action is performed in connection with your Account or use of the services on the Application by You for any reason, it may terminate Your Account immediately with or without the notice.</p>
<p>You may delete Your Account by notifying Super Indo via customer support email in connection with such intention. Notwithstanding Such deletion, Users shall remain responsible and liable for any of incomplete transactions (whether proceeding before or after such deletion). Super Indo shall have no liability, and shall not be liable for any losses incurred as a result of any action relating thereto. Users shall release any and all of claims based on such action taken by Super Indo.</p>
<p>4.&nbsp; <strong>Terms and Conditions of My Super Indo Membership</strong>&nbsp;</p>
<p>a. In order to access information and/or participate in this Application, users shall first download the Application (<strong>User/s</strong> or <strong>You</strong>) through Google Playstore or Apple Store.</p>
<p>b. User shall then register or login to the Application as follows:</p>
<p>i.&nbsp; <strong>Registration:</strong></p>
<ul>
<li>Upon registration, You shall be requested to fill out an email and create a password and then shall be requested to fill the correct personal data consisting of Your name, mobile phone number, date of birth and location. For additional data requested that is voluntary (optional), but in order to enjoy My Super Indo Membership Benefit in full, we encourage You to complete it.</li>
<li>Once You have completed the registration, You shall get the account name (user name) by using the email address and password of User. Make sure You as User secure Your account name and password, and do not notify it to anyone.</li>
</ul>
<p>ii.&nbsp; Login:&nbsp; user logs in by using account name in the form of registered email address and password.</p>
<p>c. User shall be responsible for all activities performed by the use of its name and password. User shall also be responsible to promptly notify Super Indo if the User name and password have been illegally used by other parties or other breach of security occurs.</p>
<p>d. We shall be entitled at any time to request User to correct its account name (user name) and User password if required for access security.</p>
<p>e. All accounts shall be registered with valid and active email addresses that User may access regularly so that emails may be sent to User.&nbsp;</p>
<p>f. Registered Accounts on behalf of someone elses email address, or with a temporary email address shall be closed without prior notice. We shall be entitled to request User to revalidate its account if we are aware that such User is using an invalid and inactive email address.</p>
<p>g. We shall be entitled to close the account if User is proven using IP proxy (Internet Protocol Address) to hide multiple User IDs, or if User is not located in Indonesia and pretends to be an Indonesian User, or potentially disrupts My Super Indo or Application services in any way or commits any other offense.</p>
<p>h. By providing Your personal data as referred to in Number 4(b) of these Terms and Conditions (<strong>Personal Information</strong>) through the Application and/or through My Super Indo membership service, You as User give consent to Super Indo to:</p>
<p>i. Store and use Personal information to verify Your age and adherence to internal Super Indo Policies</p>
<p>ii. Send information to You in any way (letter, email, SMS, phone, etc.) of communications in connection with the promotion provided by Super Indo,&nbsp; including but not limited to information and materials on events held by Super Indo, health and lifestyle tips/articles, cooking and food inspiration, the latest products or services of Super Indo, health&nbsp; and lifestyle tips and other information &nbsp; with respect to Super Indo, to the extent permitted by applicable law;</p>
<p>iii. Process Personal Information in a country other than the country where You signed up (for instance, a database location in one country and remotely access a database from another country);</p>
<p>iv. Disclose Personal Information to Super Indo service providers for the purpose above and/or to Super Indo affiliates and/or third parties appointed by Super Indo to conduct the above matters for its sole purpose as well as (if required by law) to the authorities; and/or&nbsp;&nbsp;</p>
<p>v. Use any feedbacks, comments, drawings, stories and/or writings that You provided to the Application for any purpose, including but not limited to internal and external Super Indo communications or promotion materials.</p>
<p>i. Personal information voluntarily provided by User to Super Indo through this Application may be used by My Super Indo or Super Indo, either directly or through a third party, in order to contact User to offer the selected products or services, unless the user states that it is not willing to accept the offer on the subscription form. Such information may also be internally used in Indonesia to assess the functionality of this Application and the needs of User.</p>
<p>5. <strong>USE OF THE APPLICATION</strong>&nbsp;</p>
<p>a. This Application and all substances/contents or materials therein may only be used for &nbsp; the personal use of Users, not for commercial purposes.</p>
<p>b. Users are allowed to cite or download part or all of the materials or contents we provide in Super Indo Application and social media as long as they include the source, i.e. Our Application or Site. We also encourage Users to use sharing features to social media when giving ratings in the Application (when this function is available)</p>
<p>c. Users may not distribute, modify, transmit, repost or otherwise use the materials on this Application for general or commercial purposes without the prior written consent of Super Indo. All copyrights and any other titles in materials downloaded (downloaded material) or captured shall be kept and maintained.</p>
<p>d. The use of This Application shall be at User personal risk. Super Indo or any other party involved in the creation, building and/or management of this Application shall not be liable for all direct, accidental,&nbsp; consequential,&nbsp; indirect or incidental loss or damages arising out from the access and use of this Application, including but not limited to :&nbsp; damages,&nbsp; or&nbsp; viruses that&nbsp; may&nbsp; infect computer or mobile equipment without limitation to the previous provisions, anything on This Application is provided&nbsp; to&nbsp; users&nbsp; &lsquo;As-is-where -is&rsquo; without guarantees of its content or guarantees of its worth and its conformity to a specific purpose, or there are no guarantees of freedom from violation of the law.</p>
<p>6. <strong>INTELLECTUAL PROPERTY RIGHTS</strong></p>
<p>a. All contents of this Application, including but not limited to texts, photographs, charts, audio materials, software or any other forms are copyright owned or held by Super Indo and/or any of its affiliates and/or other third parties appointed by Super Indo, unless otherwise stated, and shall not be used without the written permission of Super Indo or the owner of such rights. The images displayed in the Application are owned, or used shall be with permission by Super Indo. Your use of and/or modification of such images is strictly prohibited. Unauthorized use of the images may infringe copyright, brand, privacy and/or applicable regulation may be subject to legal sanctions in accordance with applicable provisions. Contents or materials contained in This Application may be accessed by You in accordance with applicable terms and conditions for members and non-members who wish to register My Super Indo through the Application.</p>
<p>b. User may download the materials for personal interests, as long as they are not traded and all copyrights and titles shall still be included. Copying, distributing, writing on media or other applications, broadcasting, adapting or modifying a part of the content of this Application for publication or commercial purposes is prohibited without the prior written permission from Super Indo.</p>
<p>c. When User sends or writes materials to or on this Application, User has agreed to grant&nbsp; the&nbsp; Super Indo non-exclusive, worldwide, royalty-free rights to use, reproduce, alter,&nbsp; adapt, publish, translate, create derivative works, display and/or disseminate such materials to the whole world through this Application, or in any other way without&nbsp; any&nbsp; obligation whatsoever to User.</p>
<p>d. All contents in this Application shall not be construed as a permission, by implication, prohibition or otherwise, of licenses and/or rights of the trademarks without the written permission of Super Indo. The use of the trademarks and the contents of this Application by User except which has been specified in Terms and Conditions is prohibited. Super Indo will inform User that Super Indo shall retain its intellectual property rights of this Application and its contents to the extent permitted by law, including imposing legal sanctions.</p>
<p>e. If User wishes My Super Indo to pay attention to any form of actions alleged as unlawful conduct with respect to the content posted by another Users or having been already existed in this Application, and User wishes to file a complaint, please use the&nbsp;<strong>Customer Support</strong> link that can be found on the page that contains the content provided to User.</p>
<p>f. This Application may contain links to the website of Super Indo.</p>
<p>g. We shall not represent or warrant that This Application, or any other media such as any website connected to&nbsp;<strong>My Super Indo</strong>, may be accessed at any time, or such access is uninterrupted or error-free.</p>
<p>h. We shall be entitled to amend or delete the materials on the Application at any time based upon My Super Indo consideration.</p>
<p>i. We may at any time amend these Terms and Conditions by updating the postings on the Application. By accessing the Application, You are legally bound to such revision; therefore you shall periodically access this Application or visit the&nbsp;<strong>Super Indo</strong> official website to see new Terms and Conditions.</p>
<p><br />&nbsp;</p>
<p>7. <strong>LIMITATION OF LIABILITY AND INDEMNITY</strong></p>
<p>i. <strong>Editorial Contents</strong></p>
<p>Besides based on the results of Super Indo research, information, data, photos, videos, and/or articles contained in the Application came from various reliable and validated sources by the Expert Team of My Super Indo. If You make any contact or relationship with a third party whose name is contained in the Application, it is Your personal responsibility.</p>
<p>The contents displayed on This Application may only be used for personal use, the information is public and does not constitute recommendations or advices that are specific and definite, and are not intended as medical advice as well as to replace advice of practitioners with respect to certain conditions or questions of Users regarding medical conditions. Users are expected to always seek advice from physicians or competent healthcare professionals.</p>
<p>Furthermore, Super Indo shall not be liable for any claim, loss, injury, fines, damage, costs and/or expenses which arise out as a result of the utilization of or inability to utilize some parts or all of the contents of the Application, or the neglect of an action that should be taken, as a result of using content for the Application.</p>
<p>ii. <strong>User Generated Content (UGC)</strong></p>
<p>Any communications or materials that You send through the Application or otherwise, including but not Limited to, data, inquiries, comments, suggestions or similar matters, shall be treated as non-confidential and non-proprietary information. You have agreed to grant royalty-free, non-exclusive rights or licenses, sub-licenses and rights to provide&nbsp; &nbsp; such materials to Super Indo and/or its affiliates. Further, Super Indo and/or its affiliates may use such communications or materials for any purpose whatsoever, including but not limited to, reproduction, disclosure, transmission, publication, broadcast and &nbsp; &nbsp; further postings, without having to obtain Your written consent. Furthermore, Super Indo, its affiliates and/or the parties appointed by Super Indo, are free to use the ideas, concepts, know-how or techniques contained in the communication or materials that You&nbsp; send to the Application for any purpose including, but not limited to, the development,&nbsp; manufacture and marketing of products.</p>
<p>iii. <strong>Promotion, Competitions, and/or Drawings</strong></p>
<p>Super Indo through the Application or&nbsp;<a href=http://www.superindo.co.id>www.superindo.co.id</a> site at any time may&nbsp; hold promotion activities, competitions, and/or drawings, either on its own initiative or in cooperation with other parties.&nbsp; Additional Terms and Conditions shall be provided at&nbsp; &nbsp; when such promotion activities, competitions and/or drawings take place.</p>
<p>8. <strong>Privacy Policy</strong></p>
<p>9. <strong>Disclaimer</strong></p>
<p>10. <strong>OUR POLICY ON USERS PERSONAL RIGHTS (Policy)</strong></p>
<p>11. We realize the importance of personal rights and we are committed to maintain the confidentiality of Your Personal Information in connection with the use of the Application.</p>
<p>12. <strong>Please read the statements and terms concerning this Users Personal Rights Policy.</strong></p>
<p>13. You need to understand the actions with regard to Your Personal Information. This Policy shall be subject to change without prior notice and come into effect immediately upon its posting. to find out about the changes, we encourage You to learn this Policy further and periodically.</p>
<p>1. <strong>Members Personal Information</strong></p>
<p>To access the Application, You will be requested to use a registered Account or create a new User ID by signing up. We shall ask for Your Personal Information which is true.</p>
<p>We are committed to ensure and maintain security regarding the process of collecting and processing Your Personal Information and ensure the security of Your Personal Information. However, if Your Personal Information is stolen, misused, lost and/or damaged by accidents or, due to any reason, We shall be entitled to refuse to be liable &nbsp; for any damages which may arise.</p>
<p>2. <strong>The Purpose of Collecting Members Personal Information</strong></p>
<p>The main purpose of collecting member Information is to offer the most suitable services for members when they visit and use our services from the Application at no cost. At certain moments, we will send You a notification or email to inform news, activities and services. However, if You are not interested or do not want such news and information, You may change Your personal service at any time.</p>
<p>3. <strong>Safeguard Members Personal Information</strong></p>
<p>If We require additional personal information, We will collect it and keep the confidentiality of such data. In certain events when We require to disclose Your Personal Information to a third party, we will notify You such matter in advance. Such members shall always be entitled to approve or disapprove such disclosure, or even reject such service as they desire.&nbsp;</p>
<p>Certain promotion or marketing activities on/may be supported by other companies, trade partners or business alliances. All or part of the information about the members collected through such activities may be provided to the corporate sponsors, and in this case the members shall be notified in advance before the information is disclosed to the third party. In this case, the members shall be entitled to not participate in such activities.</p>
<p>Note: When You visit a website that has a connection to/they may also collect Your Personal Information.</p>
<p>In such event, the information collected by other parties does not include part of this personal rights policy.</p>
<p>4. <strong>Disclosure of Personal Information</strong></p>
<p>We shall not disclose Personal Information of members to any other party, unless You permit as delivered in the beginning section for Your consent or in these following circumstances:</p>
<p><strong>Information provided to trading partners and/or business alliance or business sponsor</strong>. Super Indo may disclose personal information to business partners and business sponsors. Super Indo will inform this matter in advance before disclosing or transferring Your membership Information. Details of&nbsp; &nbsp; Information Disclosure can be found at the beginning section of Terms and Conditions.</p>
<p><strong>In some promotion programs, program sponsor may be able to request additional information</strong>. For this information, Super Indo shall reserve the right to disclose Your Personal with the aim that we shall be effectively responsive to Your needs in the future.</p>
<p><strong>At some times You may get an offer of information or special offers from third parties</strong>. If You accept the offer, Your name and email address shall be disclosed.</p>
<p><strong>We may disclose information in these following respects:</strong></p>
<p>If Super Indo considers that the disclosure of that information will affect classifications, contacts, and legal actions against persons violating Our terms of service or create certain impacts or losses to us by interfering with or violating any rights or intellectual property, intentionally or otherwise.</p>
<p>In addition, We may disclose information when requested by the authorities in accordance with applicable law, or with any other purpose that we deem necessary to maintain and improve the services, or for the development and expansion of Our goods and services.</p>
<p>5. <strong>Choice of members in terms of information collection, use and disclosure</strong></p>
<p>If You indicate a desire to receive the information about our products and services, we will send You emails about products and services that is interesting to You.</p>
<p>If You do not wish to receive any information or news from us, please show it on personal settings that You do not want it. In addition, You may use Your User ID and password to correct and/or modify information for specific promotion programs. We may request Your email address to update information. Therefore, You may receive an automated email containing our promotions of certain products&nbsp;&nbsp;</p>
<p>Super Indo shall not sell or rent Your Personal Information to other parties. However, we&nbsp; may disclose Your personal information to Super Indo service providers and/or Super Indo affiliates and/or third parties appointed by Super Indo in accordance with the purpose of the collection&nbsp; and&nbsp; processing of personal&nbsp; information&nbsp; as&nbsp; disclosed&nbsp; in the beginning&nbsp; section of Terms and Conditions,&nbsp; such as : for the implementation of age verification and&nbsp; the compliance with Super Indo internal policies and/or contacting You to provide&nbsp; information and tips on healthy life, parenting and products information; processing&nbsp; &nbsp; Personal Information in a country other than the country where You signed up; (if&nbsp; required by law) disclosing to the authorities; and using the feedbacks, comments,&nbsp; images, stories and/or writings that You provide to the Application for any purposes,&nbsp; including&nbsp; but&nbsp; not&nbsp; limited to the internal and external communication or promotion materials of Super Indo.</p>
<p>6. <strong>Changes and deletions of Personal Information&nbsp;</strong></p>
<p>Members may change, update, and/or adjust Personal Information by using their User IDs and passwords whenever they want to. For the deletion of Personal Information, members are required to inform Super Indo Customer Care.</p>
<p>If You forgot Your user ID and password, You may visit the main page or the member registration page and click on Forgot Password.&nbsp;</p>
<p>The program will take You to the Forgot Password page. You will be requested to type&nbsp; &nbsp; the email address used for registration and the program will automatically send User ID and password to the registered email address.</p>
<p>7. <strong>Warnings about lost information, used improperly and/or information changes.</strong></p>
<p>All members shall have their own passwords to be able to access Personal Information, so that only they can access personal data. You may correct, update, and adjust Your Personal Information by using Your User ID and password. Super Indo strongly encourages You to keep Your login name and password confidential. Super Indo shall not request &nbsp; Your password, by phone or email. It has been understood that sending information through the internet is not 100% secure. Therefore, in this case, we shall not guarantee the security of any information via the internet, whether data entered into Us or data came out to You. However, we shall try our best to secure all the data streams through &nbsp; such internet.</p>
<p>8. <strong>Miscellaneous</strong></p>
<p>Be careful when You disclose Your Personal Information when You are online, such as&nbsp; &nbsp; &nbsp; &nbsp; currently using message boards, chatting rooms, and emails. Such information may be collected and used by other parties. Therefore, keep the confidentiality of Your personal information, User ID and password.</p>
<p>14. <strong>Regulations</strong></p>
<p>1. <strong>My Super Indo</strong> does not control, filter, and/or edit the content posted by Users on this Application. However,&nbsp;<strong>My Super Indo</strong> reserves the right, but shall not be obliged, in accordance with the sole discretion of&nbsp;<strong>My Super Indo</strong>, to filter in advance, edit, reject and/or dispose of any content that has been sent or posted on this Application by users.&nbsp;<strong>My Super Indo</strong> shall not be liable in any way for doing so.</p>
<p>2. Users are not permitted to use this Application (or other&nbsp;<strong>My Super Indo</strong> services) for reasons or purposes that violate the law, defame, interfere, harass, jeopardize, condemn, invade privacy and/or an act in violation of the law Specifically, Users shall not be permitted to send anything which, according to&nbsp;<strong>Super Indo</strong>, jeopardizes the reputation or efforts of&nbsp;<strong>Super Indo</strong> and/or attacks other Users.</p>
<p>3. Users shall ensure that any postings or materials sent by Users on this Application do not contain viruses or are not malicious or destructive files that are made to harm computer performance or telecommunication equipment.</p>
<p>4. Users shall not be allowed to take any action that may interfere with the smoothness, use and convenience of this Application (and its services) for Super Indo and/or other Users. Users are prohibited from doing activities that do not comply with the purpose of the use this Application, including but not limited to commercial activities in any kind.</p>
<p><strong>Disclaimer&nbsp;</strong></p>
<p><strong>Super Indo</strong> strives to present information in a complete and accurate manner. However,&nbsp;<strong>Super Indo</strong> shall not provide assurance that the information in this Application is entirely complete, accurate, and trustworthy.&nbsp;<strong>Super Indo</strong> shall not also provide assurance that the access into this Application is barrier-free, error-free, virus-free, or this Application is secure.&nbsp;<strong>Super Indo</strong> shall not be liable for any losses arising out from any actions in connection with the use of the information displayed. Risk which arises out from the Users reliance on the information displayed on this Application shall be the personal responsibility of Users. Information displayed in&nbsp;<strong>My Super Indo</strong> application is public and only for reference or testimonial.</p>
</li>
</ol>`"></div>
</template>

<style scoped>
  div {
    text-align: left;
    padding: 50px 20px;
    height: 90vh;
    overflow: scroll;
  }
</style>
