import { createStore } from "vuex"
import mobile from './modules/mobile/reset-password-verification'

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    mobile
  }
})
