import { createRouter, createWebHistory } from "vue-router"
// import { isLogin } from '@/utils/auth'
import EmptyPageLayout from '../components/layouts/EmptyPage.vue'
// import Login from '@/components/pages/Login'

const routes = [
  {
    path: '/',
    component: EmptyPageLayout,
    redirect: '/login',
    children: [
      {
        path: 'mobile/reset-password',
        name: 'MobileResetPassword',
        component: () => import(/* webpackChunkName: "forgot" */ '../components/pages/mobile/ResetPassword.vue'),
        meta: {
          public: true,
          onlyWhenLoggedOut: true
        }
      },
      {
        path: 'mobile/email-verification',
        name: 'EmailVerification',
        component: () => import(/* webpackChunkName: "forgot" */ '../components/pages/mobile/EmailVerification.vue'),
        meta: {
          public: true,
          onlyWhenLoggedOut: true
        }
      },
      {
        path: 'mobile/failed-page',
        name: 'FailedPage',
        component: () => import(/* webpackChunkName: "forgot" */ '../components/pages/mobile/FailedPage.vue'),
        meta: {
          public: true,
          onlyWhenLoggedOut: true
        }
      },
      {
        path: 'mobile/success-page',
        name: 'SuccessPage',
        component: () => import(/* webpackChunkName: "forgot" */ '../components/pages/mobile/SuccessPage.vue'),
        meta: {
          public: true,
          onlyWhenLoggedOut: true
        }
      },
      {
        path: '/terms',
        name: 'Terms',
        component: () => import(/* webpackChunkName: "banners" */ '../components/pages/Term.vue'),
        meta: {
          public: true,
          onlyWhenLoggedOut: true
        }
      },
      {
        path: '/privacy',
        name: 'Privacy',
        component: () => import(/* webpackChunkName: "banners" */ '../components/pages/Privacy.vue'),
        meta: {
          public: true,
          onlyWhenLoggedOut: true
        }
      }
    ]
  },
  
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "not-found" */ '../components/pages/NotFound.vue'),
    meta: {
      public: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(''),
  routes
})

router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public)
  const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut)
  const loggedIn = false
  if (to.fullPath == '/login') {
    window.location.href = 'https://www.superindo.co.id/'
  }
  
  // let userRole;

  // if(loggedIn){
  //   userRole = getUserRole()
  // }

  // to.matched.map(record => {
  //   if (record.meta.roles === undefined) {
  //     next()
  //   }else{
  //     if(record.meta.roles.some(item => item == userRole)){
  //       next()
  //     }else{
  //       next("/admin/unauthorized")
  //     }
  //   }
  // })

  if (!isPublic) {
    return next({
      path:'/login',
      query: {redirect: to.fullPath}  // Store the full path to redirect the user to after login
    })
  }

  // Do not allow user to visit login page or register page if they are logged in
  if (loggedIn && onlyWhenLoggedOut) {
    return next('/admin')
  }

  next()
})

export default router
