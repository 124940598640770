<template>
  <div class="failed-page uk-flex uk-flex-middle uk-flex-column">
    <img
      :src="`${images}/logo-superindo.png`"
      alt="logo-lsi"
    >
    <p>Lebih Segar, Lebih Hemat, Lebih Dekat</p>
    <div class="box-failed">
      <div class="box-img">
        <img
          :src="`${images}/icon/ic_close_bg_red.svg`"
          alt="ic-failed"
        >
      </div>
      <h4>{{ getToggleModalAddEditDiscard.message }}</h4>
    </div>
  </div>
</template>

<script>
import { PREFIX_IMAGE } from '../../../utils/constant'
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    images: PREFIX_IMAGE
  }),
  computed: {
    ...mapGetters({
      getToggleModalAddEditDiscard: 'mobile/get_toggle_modal_add_edit_discard'
    })
  }
}
</script>

<style scoped>
.failed-page {
  width: 600px;
  margin: 50px auto;
}
.failed-page img {
  display: block;
  width: 120px;
}
.box-failed {
  background-color: #FFF;
  padding: 20px;
  box-shadow: 0px 8px 40px rgba(61, 87, 92, 0.08);
  border-radius: 24px;
  Width: 280px;
}
.box-failed .box-img {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 280px) {
  .failed-page p {
    font-size: smaller;
  }
  .box-failed {
    box-shadow: none;
  }
  .failed-page img, .box-img img {
    width: 80px;
  }
}
@media screen and (min-width: 281px) and (max-width: 912px) {
  .failed-page {
    padding: 50px;
    width: 100%;
    margin: 0;
  }
  .box-failed {
    box-shadow: none;
  }
  .failed-page img, .box-img img {
    width: 100px;
  }
}
</style>
