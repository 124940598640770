<template>
  <div class="success-page uk-flex uk-flex-middle uk-flex-column">
    <img
      :src="`${images}/logo-superindo.png`"
      alt="logo-lsi"
    >
    <p>Lebih Segar, Lebih Hemat, Lebih Dekat</p>
    <div class="box-success">
      <div class="box-img">
        <img
          :src="`${images}/icon/ic_check_circle_bg_green.svg`"
          alt="ic-success"
        >
      </div>
       <h4 v-if="getToggleModalAddEditDiscard.type == 'user'">
        Atur ulang kata sandi sukses, silakan kembali ke aplikasi untuk melakukan login
      </h4>
      <h4 v-else-if="getToggleModalAddEditDiscard.type == 'consumer'">
        Email Verified!
      </h4>
    </div>
  </div>
</template>

<script>
import { PREFIX_IMAGE } from '../../../utils/constant'
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    images: PREFIX_IMAGE
  }),
  computed: {
    ...mapGetters({
      getToggleModalAddEditDiscard: 'mobile/get_toggle_modal_add_edit_discard'
    })
  },
  mounted() {
    if (this.getToggleModalAddEditDiscard.type === '') {
      this.$router.push({ name: 'FailedPage' })
    }
  }
}
</script>

<style scoped>
.success-page {
  width: 600px;
  margin: 50px auto;
}
.success-page img {
  display: block;
  width: 120px;
}
.box-success {
  background-color: #FFF;
  padding: 20px;
  box-shadow: 0px 8px 40px rgba(61, 87, 92, 0.08);
  border-radius: 24px;
  Width: 280px;
}
.box-success .box-img {
  display: flex;
  justify-content: center;
}
h4 {
  font-weight: 700;
}
@media screen and (max-width: 280px) {
  .success-page p {
    font-size: smaller;
  }
  .box-success {
    box-shadow: none;
  }
  .box-success h4 {
    font-size: inherit;
  }
  .success-page img, .box-img img {
    width: 80px;
  }
}
@media screen and (min-width: 281px) and (max-width: 912px) {
  .success-page {
    padding: 50px;
    width: 100%;
    margin: 0;
  }
  .box-success {
    box-shadow: none;
  }
  .success-page img, .box-img img {
    width: 100px;
  }
}
</style>
